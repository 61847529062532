html,
body,
#root,
#main-body {
    height: 100%;
    font-family: "Roboto", sans-serif;
}

#main-body {
    overflow-y: scroll;
}

#page-content {
    flex: 1 0 auto;
}

footer {

}

.alert {
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.loading-spinner {
    display: inline-block;
    animation: loading-spinner-animation 1.2s linear infinite;
}

table.dataTable tbody th, table.dataTable tbody td {
    padding: 0 3px !important;
}

table td {
    white-space: nowrap;
    text-overflow: ellipsis !important;
}

.accordion-header > button {
    background: rgba(0, 0, 0, 0.05);
    color: black;
}

.accordion-header.complete > button {
    background: rgb(25, 135, 84);
    color: white;
}

.accordion-header.incomplete > button {
    background: rgb(255, 193, 7);
    color: black;
}

.accordion-header.incomplete * {
    color: black;
}

@media (max-width: 1000px) {
    .navbar {
        background: white !important;
        border-bottom: 1px solid #dee2e6;
    }

    #footer {
        clear: both;
        background: white !important;
        border-top: 1px solid #dee2e6;
    }

    #inner-body {
        padding-top: 15px !important;
    }
}

@keyframes loading-spinner-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}